import { Injectable, signal } from '@angular/core';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { ProductModel } from '../models/product.model';
import { HttpParams } from '@angular/common/http';
import { getLang } from 'src/app/shared/utils/utils';
import { BehaviorSubject, Subject } from 'rxjs';
import { AllergenModel } from '../../allergens/models/alergen.model';
import { ResourceListParamsModel } from 'src/app/shared/models/generic-resource.model';
import { parseQueryParams } from 'src/app/shared/utils/queryParams.utils';

@Injectable({
    providedIn: 'root'
})

export class ProductsService extends BaseService {
    itemsList$: BehaviorSubject<ProductModel[]> = new BehaviorSubject(undefined);
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedItem$: Subject<ProductModel> = new Subject();
    productAllergens$: Subject<AllergenModel[]> = new Subject();

    chainedProductsList = signal<ProductModel[]>(null);
    chainedDraft = signal<ProductModel[]>([]);


    constructor(
        protected _apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService,) {
        super(_formErrorService, _appSettingsSerice, _apiRequestService);
    }

    protected getRequestOptions() {
        return { params: new HttpParams().set("locale", getLang()) };
    }

    getChainedProductsGeneralResource(payload: ResourceListParamsModel) {
        this.getChainedList(payload)
    }

    createProduct(product: ProductModel) {
        this._appSettingsSerice.isLoading.next(true);
        this.createResorce(apiEndpoints.products, product).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on create product', error)

        });
    }

    getProduct(id: number) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(`${apiEndpoints.products}/${id}`).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data)
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get product', error)
        });
    };

    getProductAllergens(id: number) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(`${apiEndpoints.products}/${id}/product-allergens`).subscribe({
            next: apiResponse => {
                this.productAllergens$.next(apiResponse.data)
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get product allergens', error)
        });
    };

    getProducts(filter?: string, paginationSettings?: string) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(apiEndpoints.products + (paginationSettings ? paginationSettings : '') + (filter ? `${filter}` : '')).subscribe({
            next: apiResponse => {
                this.itemsList$.next(apiResponse.data);
                this.itemsData$.next(apiResponse);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get products list', error)
        });
    }
    getChainedProducts(filter?: string, paginationSettings?: any) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(apiEndpoints.products + parseQueryParams(paginationSettings) + (filter ? `${filter}` : '')).subscribe({
            next: apiResponse => {
                this.chainedDraft.set([...this.chainedDraft(), ...apiResponse.data]);
                if (apiResponse.current_page < apiResponse.last_page) {
                    this.getChainedProducts(filter, { page: apiResponse.current_page + 1, records_number: apiResponse.per_page })
                } else {
                    this.chainedProductsList.set([...this.chainedDraft()])
                    this.chainedDraft.set([])
                    this._appSettingsSerice.isLoading.next(false);
                    return
                }
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get products list', error)
        });
    }

    updateProduct(product: ProductModel) {
        this._appSettingsSerice.isLoading.next(true);
        this.updateResorce(apiEndpoints.products + '/' + product.id, product).subscribe({
            next: (apiResponse) => {
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
                this.selectedItem$.next(apiResponse.data)
            },
            error: error => this.reportError('Error on update product', error)
        });
    }

    deleteProduct(product) {
        this._appSettingsSerice.isLoading.next(true);
        this._apiRequestService.submitDeleteRequest(apiEndpoints.products + '/' + product.id).subscribe({
            next: () => {
                this._appSettingsSerice.isLoading.next(false);
                this.getProducts();
            },
            error: error => this.reportError('Error on delete product', error)
        });
    }

    updateProductImage(id: number, img: any) {
        const formData: FormData = new FormData();
        if (typeof img.image !== 'string' && !!img.image) {
            formData.append('image', img?.image, img?.image.name);
        }

        this._apiRequestService.submitPostRequest(apiEndpoints.productImage + `/${id}`, formData).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on get product image', error)
        });
    }

    removeProductImage(id: number) {
        this._apiRequestService.submitDeleteRequest(apiEndpoints.productImage + '/' + id).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on delete product image', error)
        });
    }
}
