<div *ngFor="let type of structuredList | keyvalue: originalOrder; trackBy:identifyType" class="{{notEmptyObj(structuredList[type.key])? 'pt-3': ''}}">
    <h2 *ngIf="notEmptyObj(structuredList[type.key])" [id]="type.key" class="menu-title text-center text-uppercase">{{type.key}}</h2>
    <div *ngIf="typeHasImage(type.key); else noImageProducts">
        <div *ngFor="let category of type.value | keyvalue: originalOrder; trackBy:indentifyCategory" class="image-card-custom">
            <h4 *ngIf="category.value.length" class="category-title text-center mb-0" [id]="'category'+getCategoryId(category.key)">{{category.key}}</h4>
            <div class="d-flex justify-content-center" *ngFor="let product of category.value; trackBy:identifyProduct">
                <!-- [defaultImage]="logo" [lazyLoad]="product.image"  -->
                <div class="card border-0" [ngStyle]= "{'background-image': product.image !==null ? 'url(' + product.image + ') ': 'url(' + logo + ') '}">
                    <div class="title-price h-100 w-100">
                    <div class="col-12 px-0">
                        <h2 class="card-title mb-0">{{ product.name }}</h2>
                        <div class="price d-flex align-items-baseline">
                            <div class="col-5">
                                <h4 class="item-price">{{ product.price }} <span class="font-weight-thin small">{{settingsService.currency$ | async}}</span></h4>
                            </div>
                            <div class="col-7">
                                <p class="item-secondary-details text-right mb-0">{{ math.trunc(product.amount) }} {{ product?.measurement_unit.name }} / {{ product.servings }}
                                    <span class="" *ngIf="(product.servings == 1); else multipleServings">{{ 'Serving' | translate }}</span>
                                    <ng-template #multipleServings>{{ 'Servings' | translate }}</ng-template>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mb-2 text-right more-icon-container">
                        <div *ngIf="product.is_available; else productUnavailable" class="ml-auto more-icon-white">
                            <i class="material-icons hide-icon">control_point</i>
                        </div>
                        <ng-template #productUnavailable>
                            <button class="button order-btn ml-auto unavailable">{{ 'Unavailable' | translate }}</button>
                        </ng-template>
                    </div>
                    </div>
                    
                    <div class="card-wrapper d-flex flex-column">
                    <div class="d-flex flex-column flex-wrap justify-content-between h-100">
                        <div class="row d-flex align-content-end h-100 mb-3 ">
                            <div class="col-12">
                                <p class="item-details scrollable-text small mb-3 pr-1"><span class="font-weight-bold">{{ 'Ingredients' | translate }}: </span>{{ product.ingredients }}</p>
                                <p class="item-secondary-details small"><span class="font-weight-bold">{{ 'Allergens' | translate }}:</span>
                                    <span class="" *ngIf="product?.product_allergens.length ; else noAllergens">
                                        <span *ngFor="let allergen of product.product_allergens as allergens; index as i ">
                                        {{ (allergens.length > 1)  && (i< allergens.length -1) ? (' '+ allergen.allergen.name + ','): allergen.allergen.name }}
                                        </span>
                                    </span>
                                    <ng-template #noAllergens> - </ng-template>
                                </p>
                            </div>
                            <div class="col-12 d-flex justify-content-around mb-2">
                                <div class="col-8 align-content-center secondary-details">
                                    <div class="row">
                                        <p class="item-secondary-details small mb-1"><span class="font-weight-bold">{{ 'ValuesPer' | translate}}</span> / 100{{ product?.measurement_unit.name }}:</p>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 px-0">
                                            <p class="item-secondary-details small mb-0">{{ 'Calories' | translate }}: 
                                                <span class="">{{ (product?.calories) ? ( math.trunc(product.calories)) : '-' }}</span>
                                            </p>
                                        </div>
                                        <div class="col-6 px-0">
                                            <p class="item-secondary-details small mb-0">{{ 'Proteins' | translate }}: 
                                                <!-- <span class="" *ngIf="product?.proteins; else noProteins">{{ math.trunc(product.proteins) }}gr. </span> -->
                                                <span class="">{{ (product?.proteins) ? ( math.trunc(product.proteins) + 'gr.') : '-' }}</span>
                                            </p>
                                            <!-- <ng-template #noProteins> - </ng-template> -->
                                        </div>
                                        <div class="col-6 px-0">
                                                <p class="item-secondary-details small mb-0">{{ 'Carbs' | translate }}: 
                                                    <span class="">{{ (product?.carbohydrates) ? ( math.trunc(product.carbohydrates) + 'gr.') : '-' }}</span>
                                                </p>
                                        </div>
                                        <div class="col-6 px-0">
                                            <p class="item-secondary-details small mb-0">{{ 'Fats' | translate }}: 
                                                <span class="">{{ (product.fats) ? ( math.trunc(product.fats) + 'gr.') : '-' }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-4 align-content-end pl-0">
                                    <div *ngIf="asset_id">
                                        <button *ngIf="product.is_available; else productNotAvailable" class="button order-btn ml-auto add-product-btn" (click)="addProductToCart(product)">{{ 'Order' | translate }}</button>
                                        <ng-template #productNotAvailable>
                                            <button class="button order-btn ml-auto unavailable">{{ 'Unavailable' | translate }}</button>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #noImageProducts>
        <div class="px-4 pb-4">
            <div *ngFor="let category of type.value | keyvalue: originalOrder" class="list-card-custom">
                <h4 *ngIf="category.value.length" class="category-title text-center mb-0 py-3" [id]="'category'+getCategoryId(category.key)">{{category.key}}</h4>
                <div class="row justify-content-center py-1" *ngFor="let product of category.value">
                    <div [id]="'accordion-product-'+product.id" role="tablist" class="col-12 accordion-product">
                        <div class="card-collapse mb-0">
                            <div role="tab" [id]="'headingProduct'+product.id" class="card-header">
                                <div class="mb-0 w-100 my-2">
                                    <a data-toggle="collapse" [href]="'#collapseManualOrder'+product.id" aria-expanded="false" [aria-controls]="'collapseManualOrder'+product.id" class="collapsed"> 
                                        <div class="col-12 px-0 d-flex justify-content-between">
                                            <h3 class="product-title my-2">{{ product.name }}</h3>
                                            <span><i class="material-icons float-right">keyboard_arrow_down</i></span>
                                        </div>
                                        <div class="col-12 px-0 d-flex justify-content-between align-items-baseline">
                                            <h4 class="item-price">{{ product.price }} <span class="font-weight-thin small">lei</span></h4>
                                            <p class="item-secondary-details text-right mb-0">{{ math.trunc(product.amount) }} {{ product?.measurement_unit.name }} / {{ product.servings }}
                                                <span class="" *ngIf="(product.servings == 1); else multipleServings">{{ 'Serving' | translate }}</span>
                                                <ng-template #multipleServings>{{ 'Servings' | translate }}</ng-template>
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div [id]="'collapseManualOrder'+product.id" role="tabpanel" [aria-labelledby]="'headingProduct'+product.id" [attr.data-parent]="'#accordion-product-'+product.id" class="collapse">
                                <div class="card-body px-0">
                                    <div class="details">
                                        <div class="product-no-img">
                                            <p class="item-details scrollable-text small my-3 pr-1"><span class="font-weight-bold">{{ 'Ingredients' | translate }}: </span>{{ product.ingredients }}</p>
                                        </div>
                                        <div class="align-items-end pt-3 pb-3">
                                            <div class="">
                                                <p class="item-details small">
                                                    <span class="font-weight-bold">{{ 'Allergens' | translate }}:</span>
                                                    <span class="" *ngIf="product?.product_allergens.length ; else noAllergens">
                                                    <span *ngFor="let allergen of product.product_allergens as allergens; index as i ">
                                                    {{ (allergens.length > 1)  && (i< allergens.length -1) ? (' '+ allergen.allergen.name + ','): allergen.allergen.name }}
                                                    </span>
                                                    </span>
                                                    <ng-template #noAllergens> - </ng-template>
                                                </p>
                                                <p class="item-secondary-details mb-0">{{ math.trunc(product.calories) }} {{ 'Calories' | translate }} / 100gr</p>
                                            </div>
                                            <div class="text-right">
                                                <button class="button order-btn-dark ml-auto" *ngIf="asset_id" (click)="addProductToCart(product)">{{"Order" | translate}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<div class="app-brand-footer mt-auto">
    <app-footer-cmp></app-footer-cmp>
</div>