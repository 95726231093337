import { PermissionsEnum } from '../enums/Permissions.enum';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserProfileService } from '../services/user-profile.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { take, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class RestrictPermissionsGuard implements CanActivate {
    public permission$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

    constructor(private _router: Router,
        private _userProfileService: UserProfileService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        switch (state.url) {
            case '/admin':
                return this.matchPermissions(PermissionsEnum.SELF_PROFILE_READ);
            case '/admin/brand-profile':
                return this.matchPermissions(PermissionsEnum.BRAND_PROFILE_READ);
            case '/admin/accounts':
                return this.matchPermissions(PermissionsEnum.USERS_READ);
            case '/admin/allergens':
                return this.matchPermissions(PermissionsEnum.USERS_READ);
            case '/admin/orders':
                return this.matchPermissions(PermissionsEnum.ORDERS_READ);
            case '/admin/categories':
                return this.matchPermissions(PermissionsEnum.CATEGORIES_READ);
            case '/admin/assets':
                return this.matchPermissions(PermissionsEnum.ASSETS_READ);
            case '/admin/products':
                return this.matchPermissions(PermissionsEnum.PRODUCTS_READ);
            case '/admin/types':
                return this.matchPermissions(PermissionsEnum.TYPES_READ);
            case '/admin/payments':
                return this.matchPermissions(PermissionsEnum.PAYMENTS_READ);
            case '/admin/measurement-units':
                return this.matchPermissions(PermissionsEnum.MEASUREMENT_UNITS_READ);
            case '/admin/roles':
                return this.matchPermissions(PermissionsEnum.ROLES_READ);
            case '/admin/brand-invoice-profile':
                return this.matchPermissions(PermissionsEnum.BRAND_INVOICE_PROFILE_READ);
            case '/admin/invoices':
                return this.matchPermissions(PermissionsEnum.VIEW_INVOICES_LIST);
            case '/admin/settings':
                return this.matchPermissions(PermissionsEnum.SETTINGS_READ);
            case '/admin/owners':
                return this.matchPermissions(PermissionsEnum.VIEW_OWNERS_LIST);
            case '/admin/rezervations':
                return this.matchPermissions(PermissionsEnum.VIEW_RESERVATIONS_LIST);
            case '/admin/cleaning-sessions':
                return this.matchPermissions(PermissionsEnum.VIEW_SESSIONS_LIST);
            case '/admin/services':
                return this.matchPermissions(PermissionsEnum.VIEW_SERVICES_LIST);
            case '/admin/locations':
                return this.matchPermissions(PermissionsEnum.VIEW_LOCATIONS_LIST);
            case '/admin/expense-categories':
                return this.matchPermissions(PermissionsEnum.VIEW_EXPENSES_LIST);
            case '/admin/expenses':
                return this.matchPermissions(PermissionsEnum.VIEW_EXPENSES_LIST);
            case '/admin/incomes-and-expenses-chart':
                return this.matchPermissions(PermissionsEnum.VIEW_OWNERS_LIST);
            case '/admin/monthly-expenses-chart':
                return this.matchPermissions(PermissionsEnum.VIEW_OWNERS_LIST);
            case '/admin/expenses-chart':
                return this.matchPermissions(PermissionsEnum.VIEW_OWNERS_LIST);
            case '/admin/sessions-report':
                return this.matchPermissions(PermissionsEnum.VIEW_SESSIONS_LIST);
            case '/admin/panel':
                return this.matchPermissions(PermissionsEnum.SELF_PROFILE_READ);
            case '/admin/ordered-products':
                return this.matchPermissions(PermissionsEnum.SELF_PROFILE_READ);
            case '/qr-scan/order-products':
                return this.matchPermissions(PermissionsEnum.SELF_PROFILE_READ);
            default:
                this._router.navigate(['/menu']);
                return of(false);
        }
    }

    matchPermissions(routePermission): Observable<boolean> {
        this._userProfileService.currentUser$.pipe(filter(user => !!user), take(1)).subscribe(user => {
            const hasPermission = !!user.permissions.find(permission => routePermission === permission);
            if (!hasPermission) {
                this._router.navigate(['/admin/not-allowed']);
            }
            this.permission$.next(hasPermission);
        });
        return this.permission$.asObservable();
    }
}
