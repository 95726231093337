import { filter } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { AccountModel } from '../../../models/account.model';
import { AccountsService } from '../../../services/accounts.service';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogRef } from '@angular/cdk/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { UserAccountFieldsComponent } from '../../../../../shared/components/user-account-fields/user-account-fields.component';
@UntilDestroy()
@Component({
    selector: 'app-accounts-general-info-tab',
    templateUrl: './accounts-general-info-tab.component.html',
    styleUrls: ['./accounts-general-info-tab.component.css'],
    standalone: true,
    imports: [UserAccountFieldsComponent, MatDialogModule, TranslateModule]
})
export class AccountsGeneralInfoTabComponent implements OnInit {
    public form: FormGroup;
    public selectedUser = this._usersService.currentUser;
    @Input() data: AccountModel;
    @Input() dialogRef: DialogRef;
    @Input() _saveAndClose: boolean;
    @Input() selfUpdate: boolean;

    constructor(
        private _usersService: AccountsService,
        private _userProfileService: UserProfileService) {
    }

    ngOnInit(): void {
        this._userProfileService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => this.dialogRef.close());
        this._usersService.onSaveSuccess$.pipe(filter(res => !!res && (this._saveAndClose === res)), untilDestroyed(this)).subscribe(() => this.dialogRef.close());
    }

    save() {
        this.form.valid ? this.onSubmit() : this.form.markAllAsTouched();
    }

    saveAndExit() {
        if (this.form.valid) {
            this.onSubmit();
            this._saveAndClose = true;
        } else {
            this.form.markAllAsTouched();
        }
    }

    onSubmit() {
        const user = {
            ...this.form.value,
            id: this.data?.id,
            roles_ids: [this.form.value.roles_ids]
        };

        !this.form.controls.password.value && delete user.password;
        !this.form.controls.password_confirmation.value && delete user.password_confirmation;

        if (!this.data) {
            this._usersService.createAccount(user);
        } else {
            this.selfUpdate ? this._userProfileService.updateUser(user) : this._usersService.updateAccount(user);
        }
    }

    updateUserForm(form: FormGroup) {
        this.form = form;
    }
}
