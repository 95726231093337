import { Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { AccountModel } from '../models/account.model';

@Injectable({
    providedIn: 'root'
})

export class AccountsService extends BaseService {
    itemsList = signal<AccountModel[]>([]);
    itemsData = signal<PaginationModel | undefined>(undefined);
    currentUser = signal<AccountModel | undefined>(undefined);

    constructor(
        private _userProfileService: UserProfileService,
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService,) {
        super(_formErrorService, _appSettingsSerice, apiRequestService);
    }

    protected getRequestOptions(params?: string) {
        return { params: params };
    }
    getChainedResourceList(payload: any) {
        this.getChainedList(payload)
    }

    createAccount(account: AccountModel) {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitPostRequest(apiEndpoints.accounts, account).subscribe({
            next: apiResponse => {
                this.currentUser.set(apiResponse.data);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on create user', error)

        });
    }

    getAccount(id: number, self_update?: boolean) {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitGetRequest(`${apiEndpoints.accounts}/${id}`).subscribe({
            next: apiResponse => {
                this.currentUser.set(self_update ? { self_update: true, ...apiResponse.data } : apiResponse.data)
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get user', error)
        });
    };

    // getAccountsOptions$(): Observable<any> {
    //     return this.apiRequestService.submitGetRequest(`${apiEndpoints.accounts}/dropdown-values`).pipe(map(res => ([...res.data])));
    // }

    getAccounts(filter?: string, paginationSettings?: string) {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitGetRequest(apiEndpoints.accounts + (paginationSettings ? paginationSettings : '') + (filter ? `${filter}` : '')).subscribe({
            next: apiResponse => {
                this.itemsList.set(apiResponse.data);
                this.itemsData.set(apiResponse);
                this._appSettingsSerice.isLoading.next(false);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on get available user list', error)
        });
    }

    getAccounts$(): Observable<any> {
        return this.apiRequestService.submitGetRequest(apiEndpoints.accounts);
    }

    updateAccount(account: AccountModel) {
        this._appSettingsSerice.isLoading.next(true);
        this.apiRequestService.submitPutRequest(apiEndpoints.accounts + '/' + account.id, account).subscribe({
            next: () => {
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on update available user', error)
        })
    }

    updateUserProfilePicture(userId: number, img: any) {
        const formData: FormData = new FormData();
        if (typeof img.profile_pic !== 'string' && !!img.profile_pic) {
            formData.append('profile_pic', img?.profile_pic, img?.profile_pic.name);
        }

        this.apiRequestService.submitPostRequest(apiEndpoints.userProfilePicture + `/${userId}`, formData).subscribe({
            next: apiResponse => {
                this.currentUser.set(apiResponse.data);
                this.onSaveSuccess$.next(true);
                this._userProfileService.getUser();
            },
            error: error => this.reportError('Error on get current user picture', error)
        });
    }

    removeProfileImage(id: number) {
        this.apiRequestService.submitDeleteRequest(apiEndpoints.userProfilePicture + '/' + id).subscribe({
            next: apiResponse => {
                this.currentUser.set(apiResponse.data);
                this.onSaveSuccess$.next(true);
                this._userProfileService.getUser();
            },
            error: error => this.reportError('Error on delete profile image', error)
        });
    }

}
