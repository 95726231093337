
<div class="container">
    <div *ngIf="image" class="py-5 px-3 d-flex flex-column justify-content-center align-items-center">
        <h3>{{data.product.category.name}}</h3>
        <p class="mb-4 text-center">{{data.product.name}}</p>
    
    
        <div class="qr-svg w-100" [innerHTML]="image"></div>
        
        <p class="mt-4">Prezentati codul QR pentru preluarea produsului</p>
    
        <div class="d-flex w-100 align-items-end justify-content-end">
            <button class="btn btn-primary" (click)="dialogRef.close()">{{"Close" | translate}}</button>
        </div>
    </div>
</div>