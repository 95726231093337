import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderProductsService } from '../../services/order-products.service';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { AssetModel } from 'src/app/modules/assets/models/assets.model';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
@UntilDestroy()
@Component({
    selector: 'app-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatButtonModule]
})
export class ShoppingCartComponent {
    @Input() selectedAsset: AssetModel
    @Output() openShoppingList: EventEmitter<boolean> = new EventEmitter();
    public myOrders;

    constructor(
        private _orderProductService: OrderProductsService,
        private _lsService: LocalStorageService,
        public _settingsService: SettingsService,
        private cd: ChangeDetectorRef
    ) {
        this._settingsService.getSettings();
        this._orderProductService.itemsList$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.cd.markForCheck();
            this.myOrders = data.customers.find(order => order.id === JSON.parse(this._lsService.getValue('customerData'))?.id);
        })
    }
    countProducts(e) {
        return e ? e.map(item => parseFloat(item.quantity)).reduce((acc, current) => acc + current, 0) : 0;
    }
    calculateProductsSum(e) {
        return e ? e.map(item => parseFloat(item.product.price) * parseFloat(item.quantity)).reduce((acc, current) => acc + current, 0) : 0;

    }
}
