import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class ImageViewerService {

    constructor(private sanitizer: DomSanitizer) { }

    viewImage(file: any, subj: Subject<any>) {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onloadend = () => {
            const base64data = this.sanitizer.bypassSecurityTrustHtml(reader.result.toString());
            subj.next(base64data);
        }
    }
}
