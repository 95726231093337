import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { HttpParams } from '@angular/common/http';
import { getLang } from 'src/app/shared/utils/utils';
import { OrderProductModel } from '../models/order-roduct.model';
import { OrdersService } from './oerders.service';

@Injectable({
    providedIn: 'root'
})

export class OrderProductsService extends BaseService {
    itemsList$: Subject<OrderProductModel> = new Subject();
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedItem$: Subject<OrderProductModel> = new Subject();
    addOrderProductError$: BehaviorSubject<any> = new BehaviorSubject(undefined);
    orderProductsChanged$: BehaviorSubject<any> = new BehaviorSubject(undefined);

    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService,
        private _ordersService: OrdersService) {
        super(_formErrorService, _appSettingsSerice, apiRequestService);
    }

    protected getRequestOptions(param) {
        let parammeters = param ? { params: new HttpParams().set("locale", getLang()).append('customer_id', param['customer_id']) } : { params: new HttpParams().set("locale", getLang()) }
        return parammeters;
    }



    getOrderProducts(orderProduct: OrderProductModel, first?: boolean) {
        first && this._appSettingsSerice.isLoading.next(true);
        let payload = orderProduct.customer_id ? { customer_id: orderProduct.customer_id } : null;
        this.getResorce(`${apiEndpoints.orders}/${orderProduct.id}/order-products`, payload).subscribe({
            next: apiResponse => {
                this.itemsList$.next(apiResponse.data);
                this.itemsData$.next(apiResponse);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get order products', error)
        });
    }

    getOrderProduct(id: string) {
        this._appSettingsSerice.isLoading.next(true);
        this.getResorce(apiEndpoints.orderProducts + '/' + id).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get order product', error)
        });
    }
    getOrderProducts$(orderProduct: OrderProductModel, first?: boolean) {
        first && this._appSettingsSerice.isLoading.next(true);
        let payload = orderProduct.customer_id ? { customer_id: orderProduct.customer_id } : null
        return orderProduct.id ? this.getResorce(`${apiEndpoints.orders}/${orderProduct.id}/order-products`, payload) : of(null)
    }

    addProductToOrder(orderProduct: any, orderId?: number) {
        this._appSettingsSerice.isLoading.next(true);
        this.createResorce(apiEndpoints.orderProducts, orderProduct).subscribe({
            next: apiResponse => {
                this.selectedItem$.next(apiResponse.data);
                this._appSettingsSerice.isLoading.next(false);
                this.onSaveSuccess$.next(true);
                this.getOrderProducts({ id: apiResponse.data.order_product.order_id, customer_id: orderProduct.customer_id })
                this.orderProductsChanged$.next(true)
            },
            error: error => {
                this.reportError('Error on add order product', error)
                this.addOrderProductError$.next({ error, orderProduct })
            }
        });
    };

    updateOrderProduct(id: string, orderProduct: any) {
        this._appSettingsSerice.isLoading.next(true);
        this.updateResorce(apiEndpoints.orderProducts + '/' + id, orderProduct).subscribe({
            next: (apiResponse) => {
                this.onSaveSuccess$.next(true);
                this._appSettingsSerice.isLoading.next(false);
                this.selectedItem$.next(apiResponse.data);
                this._ordersService.orderProductsChanged$.next(true);
                this.orderProductsChanged$.next(true);
            },
            error: error => this.reportError('Error on update order product', error)
        });
    }

    deleteOrderProduct(product) {
        this._appSettingsSerice.isLoading.next(true);
        this.deleteResorce(apiEndpoints.orderProducts + '/' + product.id, { customer_id: product.customer_id }).subscribe({
            next: () => {
                this.getOrderProducts({ customer_id: product.customer_id, id: product.order_id })
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on delete order product', error)
        });
    }
}
