import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { apiEndpoints, ApiRequestService } from '../../../shared/services/api-request.service';
import { BaseService } from '../../../shared/services/base.service';
import { AppSettingsService } from '../../../shared/services/app-settings.service';
import { FormErrorService } from '../../../shared/services/form-error.service';
import { SettingModel } from '../models/settings.model';
import { RequestMethodsEnum } from 'src/app/shared/enums/RequestMethods.enum';

@Injectable({
    providedIn: 'root'
})

export class SettingsService extends BaseService {
    itemsList$: BehaviorSubject<SettingModel[]> = new BehaviorSubject(undefined);
    selectedSetting$: BehaviorSubject<SettingModel> = new BehaviorSubject(undefined);
    suportedLocales$: BehaviorSubject<any> = new BehaviorSubject(undefined);
    currency$: BehaviorSubject<any> = new BehaviorSubject(undefined);
    waiterPanelRefresh$: BehaviorSubject<any> = new BehaviorSubject(undefined);
    orderedProductsRefreshRefresh$: BehaviorSubject<any> = new BehaviorSubject(undefined);

    constructor(
        protected _apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        protected _appSettingsSerice: AppSettingsService,) {
        super(_formErrorService, _appSettingsSerice, _apiRequestService);
    }

    verifyTokenStatus() {
        return this._apiRequestService.submitGetRequest(apiEndpoints.tokenStatus);
    }

    getSettings() {
        this._apiRequestService.submitGetRequest(apiEndpoints.settings).subscribe({
            next: apiResponse => {
                this.currency$.next(apiResponse.data.find(setting => setting.name === 'Currency')?.value);
                this.waiterPanelRefresh$.next(apiResponse.data.find(setting => setting.name === 'Dashboard Refresh')?.value);
                // changeCurrency(this.currency.value)
                this.itemsList$.next(apiResponse.data);
            },
            error: error => this.reportError('Error on get settings', error)
        })
    }

    getSetting(id: number) {
        this._apiRequestService.submitGetRequest(apiEndpoints.settings + `/${id}`).subscribe({
            next: apiResponse => this.selectedSetting$.next(apiResponse.data),
            error: error => this.reportError('Error on get setting', error)
        })
    }
    getSupportedLocales() {
        this._apiRequestService.submitGetRequest(apiEndpoints.supportedLocales).subscribe({
            next: apiResponse => this.suportedLocales$.next(apiResponse),
            error: error => this.reportError('Error on get supported locales', error)
        })
    }

    updateSetting(setting: SettingModel) {
        this._apiRequestService.submitPutRequest(apiEndpoints.settings + '/' + setting.id, setting).subscribe({
            next: apiResponse => {
                this.selectedSetting$.next(apiResponse.data)
                this.refreshListAfterChange(apiResponse.data, RequestMethodsEnum.Update, this.itemsList$);
                this.onSaveSuccess$.next(true);
            },
            error: error => this.reportError('Error on update setting', error)
        })
    }

    refreshListAfterChange(serverResponse: any, type: RequestMethodsEnum, list$: BehaviorSubject<any>) {
        const newList = [...list$.value];
        const index = newList.findIndex(item => item.id === serverResponse?.id);

        switch (type) {
            case RequestMethodsEnum.Create:
                newList.push(serverResponse);
                break;

            case RequestMethodsEnum.Update:
                newList[index] = serverResponse;
                break;
        }

        list$.next(newList);
    }
}
