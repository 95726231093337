<div *ngIf="selectedAsset?.order; else noOrder">
    <button mat-mini-fab class="btn-cart pt-2" (click)="openShoppingList.emit(true)">
        <span class="notification quantity-notif" *ngIf="myOrders?.order_products.length">{{countProducts(myOrders.order_products)}}</span>
        <span class="notification price-notif" *ngIf="myOrders?.order_products.length">{{calculateProductsSum(myOrders.order_products).toFixed(2)}} {{_settingsService.currency$.value}}</span>
        <div class="cart-icon"><i class="material-icons">shopping_cart</i></div>
    </button>
</div>

<ng-template #noOrder>
    <button mat-mini-fab class="btn-cart text-white pt-2" (click)="openShoppingList.emit(false)">
        <div class="cart-icon"><i class="material-icons">add_shopping_cart</i></div>
    </button>
</ng-template>