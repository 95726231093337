import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { apiEndpoints, ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { FormErrorService } from 'src/app/shared/services/form-error.service';
import { HttpParams } from '@angular/common/http';
import { getLang } from 'src/app/shared/utils/utils';
import { OrderedProductModel } from '../models/ordered-roduct.model';
import { ImageViewerService } from 'src/app/shared/services/image-viewer.service';

@Injectable({
    providedIn: 'root'
})

export class OrderedProductsService extends BaseService {
    itemsList$: Subject<OrderedProductModel> = new Subject();
    itemsData$: BehaviorSubject<PaginationModel> = new BehaviorSubject(undefined);
    selectedItem$: Subject<OrderedProductModel> = new Subject();
    qrCode$: Subject<string> = new Subject();

    constructor(
        private apiRequestService: ApiRequestService,
        protected _formErrorService: FormErrorService,
        private _imageViewerService: ImageViewerService,
        protected _appSettingsSerice: AppSettingsService) {
        super(_formErrorService, _appSettingsSerice, apiRequestService);
    }

    protected getRequestOptions(param) {
        let parammeters = param ? { params: new HttpParams().set("locale", getLang()).append('customer_id', param['customer_id']) } : { params: new HttpParams().set("locale", getLang()) }
        return parammeters;
    }

    getChainedResourceList(payload: any) {
        this.getChainedList(payload)
    }

    getOrderedProducts(filters?: string, queryParams?: string, first?: boolean) {
        first && this._appSettingsSerice.isLoading.next(true);
        this.getResorce(apiEndpoints.orderProducts + (queryParams ? `${queryParams}` : '') + (filters ? `${filters}` : '')).subscribe({
            next: apiResponse => {
                this.itemsList$.next(apiResponse.data);
                this.itemsData$.next(apiResponse);
                this._appSettingsSerice.isLoading.next(false);
            },
            error: error => this.reportError('Error on get order products', error)
        });
    }
    getOrderedProducts$(filters?: string, queryParams?: string) {
        return this.getResorce(apiEndpoints.orderProducts + (queryParams ? queryParams : '') + (filters ? `${filters}` : ''));
    }

    getProductQr(id) {
        return this.apiRequestService.submitGetRequest(apiEndpoints.orderProducts + '/' + id + '/qr-image', { responseType: 'blob' })
    }

    getProductQrCode(id: number) {
        this._apiRequestService.submitGetRequest(apiEndpoints.orderProducts + '/' + id + '/qr-image', { responseType: 'blob' }).subscribe({
            next: apiResponse => {
                this._imageViewerService.viewImage(apiResponse, this.qrCode$);
            },
            error: error => this.reportError('Error on get qr code', error)
        })
    }

}
