import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ConfirmedOrUnavailablePipe',
    standalone: true
})
export class ConfirmedOrUnavailablePipe implements PipeTransform {

    transform(value: any, element: HTMLElement): string {
        if (value.confirmed_at) {
            return "confirmed"
        }
        if (value.unavailable_at) {
            return "unavailable"
        }
        return 'unprocessed'

    }
}