import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { OrderedProductsService } from 'src/app/modules/ordered-products/services/ordered-products.service';
import { ShoppingListComponent } from 'src/app/modules/orders/components/shopping-list/shopping-list.component';
@UntilDestroy()
@Component({
    selector: 'app-product-qr',
    templateUrl: './product-qr.component.html',
    styleUrls: ['./product-qr.component.css'],
    standalone: true,
    imports: [NgIf, TranslateModule]
})
export class ProductQrComponent {
    image: string;

    constructor(public dialogRef: MatDialogRef<ShoppingListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _orderedProductsService: OrderedProductsService) { }

    ngOnInit() {
        this._orderedProductsService.getProductQrCode(this.data.id);
        this._orderedProductsService.qrCode$.pipe(untilDestroyed(this)).subscribe((qrCode: string) => this.image = qrCode);
    }
}
