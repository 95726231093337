import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'LabelWithUnavailablePipe',
    standalone: true
})

export class LabelWithUnavailablePipe implements PipeTransform {
    constructor(public translateService: TranslateService) { }
    transform(value: any, element: HTMLElement, field: string) {
        if (element.parentElement) {
            element.parentElement ? element.parentElement.style.textAlign = 'center' : null;
        }
        if (value['unavailable_at']) {
            element.classList.add('badge', `badge-unavailable-p`);
            return !!value[field]
        }
        element.classList.add('badge', `badge-${value[field] ? 'success' : 'danger'}`);
        return !!value[field];
    }
}