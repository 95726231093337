import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserProfileService } from './shared/services/user-profile.service';
import { LangService } from './shared/services/lang.service';
import { CURRENT_USER } from './shared/services/local-storage.service';
import { AppSettingsService } from './shared/services/app-settings.service';
import { BrandProfileService } from './modules/brand-profile/services/brand-profile.service';


@UntilDestroy()
@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [RouterOutlet]
})

export class AppComponent implements OnInit, OnDestroy {
    private _router: Subscription;

    constructor(
        private router: Router,
        private _userProfileService: UserProfileService,
        private _langService: LangService,
        private _appSettingsService: AppSettingsService,
        private _brandProfileService: BrandProfileService
    ) { }

    ngOnInit() {
        this._appSettingsService.getAppVersion();
        this._userProfileService.updatedUser$.pipe(filter(user => !!user), untilDestroyed(this)).subscribe(user => {
            localStorage.setItem(CURRENT_USER, JSON.stringify(user));
        });

        this._brandProfileService.currentBrandProfile$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => document.title = data.name + ' - menu by Sip & Bite')

        if (localStorage.getItem('lang')) {
            this._langService.setLang(localStorage.getItem('lang'));
        }

        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            const body = document.getElementsByTagName('body')[0];
            const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
            if (body.classList.contains('modal-open')) {
                body.classList.remove('modal-open');
                modalBackdrop.remove();
            }
        });
    }

    ngOnDestroy() {
        this._router.unsubscribe();
        this._userProfileService.updatedUser$.unsubscribe();
    }
}