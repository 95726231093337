<section class="table-list-section child-dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <span>{{ 'OrderedProducts' | translate | uppercase }}</span>
            </div>
            <div class="col-12 mt-4">
                <app-table-filters *ngIf="table" [filters]="filters" [tableId]="table.tableId"
                    [queryParams]="queryParams" (applyFilters)="applyFilters($event)"></app-table-filters>
            </div>
            <div class="col-12">
                <app-regular-table #table
                    [headerRow]="tableHeaders"
                    [service]="orderedProductsService" 
                    [dataList]="orderedProductsService.itemsList$ | async"
                    [lastPage]="itemsData?.last_page" 
                    [rowPossibleActions]="rowPossibleActions" 
                    [pagination]="itemsData"
                    [length]="itemsData?.total" 
                    [tableId]="'ordered-products-records-tbl'" 
                    (onPageChanged)="updatePage($event)"
                    (actionType)="tableActionClicked($event)"
                    (changeLang)="changeLang()">
                </app-regular-table>
            </div>
        </div>
    </div>
</section>