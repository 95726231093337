import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AssetsService } from 'src/app/modules/assets/services/assets.service';
import { BrandProfileService } from 'src/app/modules/brand-profile/services/brand-profile.service';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { NgIf, NgFor, CommonModule } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-welcome-page',
    templateUrl: './welcome-page.component.html',
    styleUrls: ['./welcome-page.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, CommonModule]
})
export class WelcomePageComponent implements OnInit {

    @Output() changeLang = new EventEmitter<any>();

    constructor(
        public dialogRef: MatDialogRef<WelcomePageComponent>,
        @Inject(MAT_DIALOG_DATA) public assetId: string,
        public brandProfileService: BrandProfileService,
        public settingsService: SettingsService,
        public assetsService: AssetsService,
    ) {
    }

    ngOnInit() {
    }

    changeLanguage(lang) {
        this.changeLang.emit(lang);
        this.dialogRef.close();
    }
}

