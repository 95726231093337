export enum TableCellType {
    DATE = 'date',
    COUNT_WITH_POPOVER = 'count-with-popover',
    DATE_HOUR_FORMAT = 'date-hour-format',
    TOOLTIP = 'tooltip',
    DEFAULT_PROFILE = 'default_profile',
    FULL_NAME = 'full_name',
    TRANSLATE = 'translate',
    PERCENT = 'percent',
    LABEL = 'label',
    BOOLEAN_LABEL = 'boolean_label',
    BOOLEAN_LABEL_WITH_UNAVAILABLE = 'boolean_label_with_unavailable',
    EMPTY_CONTENT_TO_LINE = 'empty-content-to-line',
    DATE_FORMAT_ON_MULTIPLE_ELEMENTS = 'date-format-on-multiple-elements',
    PRICE = 'price',
    SUBSTRACT_LABEL = "subsctract-label",
    BADGE_OR_DATE = "badge_or_date",
    DATE_HOUR_FORMAT_WITH_MULTIPLE_PROPS = "date-hour-format-with-multiple-props",
    DASH_SEPARATED = "dash-separated",
    DASH_SEPARATED_TEXT = "dash-separated-text",
    CALCULATE_TOTAL_ORDER_VALUE = 'calculate-total-order-value',
    OPEN_CLOSED = "open-closed",
    ASSET_NAME = 'asset-name',
    PAYMENT_TYPE = 'cash-card',
    ASSET_TYPE = 'asset-type',
    CONFIRMED_OR_UNAVAILABLE = 'confirmed_or_unavailable',
}