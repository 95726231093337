import { Component, ElementRef, ViewChildren } from '@angular/core';
import { PermissionsEnum } from 'src/app/shared/enums/Permissions.enum';
import { PaginationModel } from 'src/app/shared/components/tables/regular-table/models/pagination.model';
import { ApplyFiltersModel, TableFilter, TableFilterTypesEnum } from 'src/app/shared/components/tables/regular-table/components/table-filters/models/table-filter.model';
import { TableDataHeaders, TableHeadersTypeEnum } from 'src/app/shared/models/table-data-headers.model';
import { RowPossibleActions } from 'src/app/shared/models/row-actions.model';
import { TableActionTypeEnum } from 'src/app/shared/enums/TableActionType.enum';
import { TableFiltersComponent } from 'src/app/shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { filter, Subscription, timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { parseQueryParams } from 'src/app/shared/utils/queryParams.utils';
import { PageEvent } from '@angular/material/paginator';
import { TranslateModule } from '@ngx-translate/core';
import { RegularTableComponent } from '../../shared/components/tables/regular-table/regular-table.component';
import { TableFiltersComponent as TableFiltersComponent_1 } from '../../shared/components/tables/regular-table/components/table-filters/table-filters.component';
import { CommonModule, NgIf, UpperCasePipe } from '@angular/common';
import { OrderedProductModel } from './models/ordered-roduct.model';
import { OrderedProductsService } from './services/ordered-products.service';
import { TableCellType } from 'src/app/shared/enums/TableCellType.enum';
import { CategoriesService } from '../categories/services/category.service';
import { ProductsService } from '../products/services/product.service';
import { booleanOptions$ } from 'src/app/shared/constants';
import { SettingsService } from '../settings/services/settings.service';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { OrdersService } from '../orders/services/oerders.service';
@UntilDestroy()
@Component({
    selector: 'app-ordered-productss',
    templateUrl: './ordered-products.component.html',
    styleUrls: ['./ordered-products.component.css'],
    standalone: true,
    imports: [NgIf, TableFiltersComponent_1, RegularTableComponent, UpperCasePipe, TranslateModule, CommonModule]
})
export class OrderedProductssComponent {
    public PermissionsEnum = PermissionsEnum;
    public itemsList: OrderedProductModel[];
    public itemsData: PaginationModel;
    public subscription: Subscription;
    public parsedFilters = '';
    public firstTimeLoading = true;
    public queryParams = {
        page: 1,
        records_number: 10
    };

    public chainedProductsPayload = {
        "filter[is_active]": true,
        page: 1,
        records_number: 100,
        table_name: 'Product',
        "fields[products]": 'id,name',
    }


    public filters: TableFilter[] = [
        {
            resourceName: 'name',
            label: 'Product',
            type: TableFilterTypesEnum.SELECT,
            controls: ['product_id'],
            firstOptionMessage: 'SelectProduct',
            style: 'col-md-4',
            dropdownId: 'ordered_product_id',
            placeholders: [''],
            optionsList$: this._productsService.chainedList$
        },
        {
            resourceName: 'category.id',
            label: 'Category',
            type: TableFilterTypesEnum.SELECT,
            controls: ['product.category_id'],
            style: 'col-md-2',
            firstOptionMessage: 'All',
            optionsList$: this._categoriesService.getCategories$()
        },
        {
            resourceName: 'is_confirmed',
            label: 'IsConfirmed',
            type: TableFilterTypesEnum.BOOLEAN_SELECT,
            controls: ['is_confirmed'],
            style: 'col-md-2',
            firstOptionMessage: 'All',
            dropdownId: 'is_confirmed',
            optionsList$: booleanOptions$()
        },
        {
            resourceName: 'is_ready',
            label: 'IsReady',
            type: TableFilterTypesEnum.BOOLEAN_SELECT,
            controls: ['is_ready'],
            style: 'col-md-2',
            firstOptionMessage: 'All',
            dropdownId: 'is_ready',
            optionsList$: booleanOptions$()
        },
        {
            resourceName: 'was_served',
            label: 'WasServed',
            type: TableFilterTypesEnum.BOOLEAN_SELECT,
            controls: ['was_served'],
            style: 'col-md-2',
            firstOptionMessage: 'All',
            dropdownId: 'was_served',
            optionsList$: booleanOptions$()
        },
    ];

    public tableHeaders: TableDataHeaders[] = [
        {
            name: 'Product',
            fieldNames: ['product.category.name', 'product.name', 'observations'],
            cssClasses: 'all',
            searchable: true,
            sorting: true,
            type: TableHeadersTypeEnum.MULTIPLE_ELEMENTS,
        },
        {
            name: 'Customer',
            fieldNames: ['customer.name'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            // cellType: TableCellType.ASSET_NAME
        },
        {
            name: 'Quantity',
            fieldNames: ['quantity'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
        },
        {
            name: 'Confirmed',
            fieldNames: ['confirmed_at'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            cellType: TableCellType.CONFIRMED_OR_UNAVAILABLE,
        },
        {
            name: 'Ready',
            fieldNames: ['ready_at'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            cellType: TableCellType.BOOLEAN_LABEL_WITH_UNAVAILABLE,
        },
        {
            name: 'Delivered',
            fieldNames: ['served_at'],
            cssClasses: 'all text-center',
            searchable: true,
            sorting: true,
            cellType: TableCellType.BOOLEAN_LABEL_WITH_UNAVAILABLE,
        },
    ];

    public rowPossibleActions: RowPossibleActions[] = [
        {
            name: 'MarkAsConfirmed',
            type: TableActionTypeEnum.Confirm,
        },
        {
            name: 'MarkAsReady',
            type: TableActionTypeEnum.Ready,
        },
        {
            name: 'MarkAsUnavailable',
            type: TableActionTypeEnum.Unavailable,
        }
    ];

    @ViewChildren('table') table: TableFiltersComponent;

    public refreshTimer = this._settingsService.orderedProductsRefreshRefresh$.value || 20000;

    constructor(public orderedProductsService: OrderedProductsService,
        private _categoriesService: CategoriesService,
        private _productsService: ProductsService,
        private _settingsService: SettingsService,
        private _sweetAlertService: SweetAlertService,
        private _ordersService: OrdersService
    ) {
        this._productsService.getChainedProductsGeneralResource(this.chainedProductsPayload);
        this.orderedProductsService.itemsData$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.itemsData = data);
    }

    ngOnInit(): void {
        this._ordersService.orderProductsChanged$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(() => this.renewSubscription())
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    tableActionClicked(e: any) {
        if (e.type === TableActionTypeEnum.Confirm) {
            this._sweetAlertService.showSwal('warning-message-confirm', () => this._ordersService.confirmOrderedProducts({ order_id: e.entry.order_id, id: e.entry.id }), "product");
        }
        if (e.type === TableActionTypeEnum.Ready) {
            this._sweetAlertService.showSwal('warning-message-ready', () => this._ordersService.confirmReadyOrderedProducts({ order_id: e.entry.order_id, id: e.entry.id }), "product");
        }
        if (e.type === TableActionTypeEnum.Unavailable) {
            this._sweetAlertService.showSwal('unavailable-product-message', () => this._ordersService.markProductAsUnavailable({ order_id: e.entry.order_id, id: e.entry.id }), "product");
        }
    }

    changeLang() {
        this.renewSubscription()
    }

    updatePage(event: PageEvent) {
        this.queryParams = {
            records_number: event.pageSize,
            page: event.pageIndex + 1
        };
        this.renewSubscription()
    }

    applyFilters(filters: ApplyFiltersModel) {
        this.parsedFilters = filters.parsedFilters;
        this.queryParams = filters.queryParams;
        this.renewSubscription()
    }

    renewSubscription() {

        this.subscription && this.subscription.unsubscribe();
        this.subscription = timer(0, this.refreshTimer).subscribe(res => {
            let a = document.querySelectorAll('.dropdown-menu.dropdown-menu-right.show');
            if (!a.length) {
                this.orderedProductsService.getOrderedProducts(this.parsedFilters, parseQueryParams(this.queryParams));
            }
        })
    }
}
