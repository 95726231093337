import { Component, Inject, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { OrderProductsService } from '../../services/order-products.service';
import { Subscription, filter, switchMap, timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { CustomersService } from 'src/app/modules/customers/services/customers.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BaseModalComponent } from 'src/app/shared/components/base-modal/base-modal.component';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { utcConvert } from 'src/app/shared/utils/utils';
import { AssetsService } from 'src/app/modules/assets/services/assets.service';
import { CustomerRequestsService } from 'src/app/modules/customer-requests/services/customer-requests.service';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormFieldErrorComponent } from '../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../../../shared/directives/form-validator.directive';
import { NgIf, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { CallWaiterFormComponent } from 'src/app/shared/components/call-waiter-form/call-waiter-form.component';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ProductQrComponent } from 'src/app/modules/qr-scan/qr-menu-product/product-qr/product-qr.component';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
@UntilDestroy()
@Component({
    selector: 'app-shopping-list',
    templateUrl: './shopping-list.component.html',
    styleUrls: ['./shopping-list.component.css'],
    standalone: true,
    imports: [PreloaderComponent, MatDialogModule, NgIf, NgFor, ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, MatSelectModule, MatOptionModule, AsyncPipe, TranslateModule, CallWaiterFormComponent]
})
export class ShoppingListComponent extends BaseModalComponent implements OnInit, OnDestroy {
    public products;
    public myOrder;
    public subscription: Subscription;
    public enableNameEdit = false;
    public firstTimeLoading: boolean = true;
    public form: FormGroup;
    public callWaiterForm: FormGroup;
    parseFloat = parseFloat;
    utcConvert = utcConvert
    @ViewChild('customerName') customerName: ElementRef;
    public selectedAsset;
    public payments = [];
    public totalPaid = 0;
    obsValue = '';
    qtyValue: number;
    nameValue = '';
    public isLoading = true;
    public isPayment: boolean;
    public requestAlreadyExist;
    public hasConfirmedProducts: boolean;
    public displayPaymentOptions: boolean;
    public pauseRefreshing = false;
    math = Math;

    public refreshTimer = this._settingsService.waiterPanelRefresh$.value || 20000;

    constructor(
        public dialogRef: MatDialogRef<ShoppingListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _orderProductService: OrderProductsService,
        public _settingsService: SettingsService,
        private _customersService: CustomersService,
        private _translateService: TranslateService,
        private _lsService: LocalStorageService,
        public appSettingsService: AppSettingsService,
        private _formBuilder: FormBuilder,
        private _assetsService: AssetsService,
        private _customerRequestsService: CustomerRequestsService,
        private _appSettingsSerice: AppSettingsService,
        private _sweetAlertService: SweetAlertService,
        private _dialogService: DialogService
    ) {
        super(dialogRef, _orderProductService);
        document.getElementsByTagName("body")[0].style.overscrollBehaviorY = "contain";
        document.getElementsByTagName("html")[0].style.overscrollBehaviorY = "contain";

        this.form = this._formBuilder.group({
            name: JSON.parse(this._lsService.getValue('customerData'))?.name,
        });

        if (data.asset.order) {
            this.subscription = timer(0, this.refreshTimer).pipe(
                switchMap(() => {
                    this.isLoading = this.firstTimeLoading ? true : false;
                    return this._orderProductService.getOrderProducts$({ id: this.data?.asset?.order?.id, customer_id: this.data.customer_id }, this.firstTimeLoading)
                })
            ).subscribe({
                next: res => {

                    if (!this.pauseRefreshing) {
                        this.isLoading = false
                        this.firstTimeLoading = false;
                        this.appSettingsService.isLoading.next(false);
                        this._orderProductService.itemsList$.next(res.data);
                        this.requestAlreadyExist = res.data.customer_request;

                    }
                }, error: (err) => {
                    this.isLoading = false;
                    if (err.error.errors.customer_id) {
                        localStorage.removeItem('customerData');
                        this._orderProductService.getOrderProducts({ id: this.data?.asset?.order?.id })
                    }
                }
            })

        }

        this._assetsService.selectedItem$.pipe(filter(data => !!data['order']), untilDestroyed(this)).subscribe(asset => {
            this.data.asset = asset;
            this.renewSubscription();
            setTimeout(() => this.data.has_order_id?.unsubscribe(), 0);
        })
        this._orderProductService.itemsList$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => {
            this.myOrder = data.customers.find(order => order.id === JSON.parse(this._lsService.getValue('customerData'))?.id);
            if (!JSON.parse(this._lsService.getValue('customerData'))?.name) this.form.controls.name.setValue(`Customer ${data.customers.indexOf(this.myOrder) + 1}`)
            this.products = data;
            this.getPayments(data);
            this.hasConfirmedProducts = data.customers.map(customer => customer.order_products).flat().some(el => el.confirmed_at && !el.payment_id)
        });
        this._orderProductService.orderProductsChanged$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(() => this.renewSubscription())

        this._customersService.selectedItem$.pipe(filter(val => !!val), untilDestroyed(this)).subscribe((customer) => this.form.controls.name.setValue(customer.name));
    }

    ngOnInit() {
        this._customerRequestsService.onSaveSuccess$.pipe(filter(res => !!res), untilDestroyed(this)).subscribe(() => this.renewSubscription());
        this.callWaiterForm = this._formBuilder.group({
            asset_id: this.data.asset?.id,
            order_id: this.data.asset?.order?.id,
            customer_id: this.data?.customer_id,
            details: "",
            type: 0
        });

        this.requestAlreadyExist = this.data.asset.customer_request ? true : false;
    }


    callWaiter(e) {
        this._assetsService.getAsset$(this.data.asset.id).subscribe(data => {
            this.requestAlreadyExist = data.data.customer_request ? data.data.customer_request : null;
            this._appSettingsSerice.isLoading.next(false);
            if (!this.requestAlreadyExist) {
                let payload = { ...this.callWaiterForm.value, type: e.requestType };
                payload.details = e.paymentType ? e.paymentType : "";
                payload.type !== 'payment' && delete payload.details;
                !payload.order_id && delete payload.order_id;

                if (payload.customer_id) {
                    this._customerRequestsService.addCustomerRequest(payload);
                    return;
                }
                this._customerRequestsService.createCustomerToMakeRequest(payload);
            }
        })
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
        document.getElementsByTagName("body")[0].style.overscrollBehaviorY = "auto";
        document.getElementsByTagName("html")[0].style.overscrollBehaviorY = "auto";
    }

    removeProduct(product) {
        this._sweetAlertService.showSwal('warning-message-ir', () => this._orderProductService.deleteOrderProduct(product), "TheProduct");
    }

    getTotal(order) {
        return order ? order.map(item => parseFloat(item.product.price) * parseFloat(item.quantity)).reduce((acc, current) => acc + current, 0) : 0;
    }

    startEditUser(e) {
        e.preventDefault()
        this.enableNameEdit = true;
        this.customerName.nativeElement.focus();
    }

    selfUpdateUser(e) {
        if (e.type === 'focusin') {
            this.enableNameEdit = true;
            this.nameValue = e.target.value;
        }
        if (this.form.valid) {
            let id = JSON.parse(this._lsService.getValue('customerData'))?.id;
            if (e.target.value !== this.nameValue) this._customersService.updateCustomer(id, this.form.controls.name.value);
        }
    }

    getCustomerName(index?: number) {
        if (JSON.parse(this._lsService.getValue('customerData'))?.name) {
            return this.form.controls.name.value;
        }
        return this._translateService.instant('Customer') + ' ' + (index + 1);
    }

    getMyOrder(customer) {
        return customer.id === JSON.parse(this._lsService.getValue('customerData'))?.id
    }

    updateQuantity(product, e) {
        if (e.type === 'focusin') this.qtyValue = e.target.value;
        let payload = {
            quantity: e.target.value,
            customer_id: product.customer_id,
            observations: product.observations
        }
        if (e.target.value !== this.qtyValue) this._orderProductService.updateOrderProduct(product.id, payload)
    }
    updateObservations(product, e) {
        if (e.type === 'focusin') this.obsValue = e.target.value;
        let payload = {
            quantity: product.quantity,
            customer_id: product.customer_id,
            observations: e.target.value
        }
        if (e.target.value !== this.obsValue) this._orderProductService.updateOrderProduct(product.id, payload)
    }

    renewSubscription() {
        this.subscription && this.subscription.unsubscribe();
        this.subscription = timer(0, this.refreshTimer).pipe(
            switchMap(() => {
                return this._orderProductService.getOrderProducts$({ id: this.data?.asset?.order?.id, customer_id: this.data.customer_id })
            })
        ).pipe(filter(res => !!res)).subscribe({
            next: res => {
                if (!this.pauseRefreshing) {
                    this.isLoading = false
                    this.appSettingsService.isLoading.next(false);
                    this._orderProductService.itemsList$.next(res.data);
                    this.requestAlreadyExist = res.data.customer_request;
                }
            },
            error: err => {
                this.isLoading = false;
                if (err.error.errors.customer_id) {
                    localStorage.removeItem('customerData');
                    this._orderProductService.getOrderProducts({ id: this.data?.asset?.order?.id })
                }
            }
        })
    }

    getPayments(data) {
        let payments = data.payments

        if (data['customers']?.length) {
            if (data['payments']?.length) {
                let paidProducts = data.customers.map(customer => customer.order_products
                    .filter(product => product.payment_id))
                    .filter(customer => customer.length)
                    .flat();
                for (let payment of payments) {
                    payment['value'] = paidProducts.filter(product => product.payment_id === payment.id)
                        .map(prod => prod.quantity * prod.unitary_price)
                        .reduce((acc, current) => acc + current, 0);
                }
                this.totalPaid = payments.map(payment => payment.value).reduce((acc, current) => acc + current);
            }
        }
        this.payments = payments
    }

    openQR(product) {
        if (product.ready_at && !product.served_at) {
            this._dialogService.openModal(product, ProductQrComponent, DimensionsEnum.AUTO);
        }

    }

}
