<app-preloader></app-preloader>

<div class="customer-modal">
    <div class="top-header py-1">
        <div class="dialog-top-header d-flex w-100" mat-dialog-title id="mat-mdc-dialog-title-0">
            <div class="col-9">
                <div class="asset" *ngIf="data?.asset">
                    <h5 class="asset-name font-custom-500 white">{{data.asset.asset_type.name}} {{data.asset.name}}</h5>
                </div>
            </div>
            <div class="col-3 text-right">
                <button class="btn btn-secondary-custom small-btn font-custom-500 white"
                        (click)="dialogRef.close()">
                    <span class="material-icons">close</span>
                </button>
            </div>
        </div>
    </div>
    <mat-dialog-content class="p-0 shopping-list-modal">
        <div class="py-3" *ngIf="data.asset.order; else noOrder">
            <div *ngIf="myOrder; else noProductsAddedYet">
                <div *ngFor="let customer of products.customers; index as customerIndex">
                    <div *ngIf="getMyOrder(customer)">
                        <ul class="px-0 py-3 my-0" *ngIf="customer?.order_products" >
                            <div class="px-0 py-0">
                                <div class="col-12 mt-lg-0 mb-md-0">
                                    <div class="card my-0 position-relative">
                                        <div [id]="'accordion-manual-customer-me'+customerIndex" role="tablist">
                                            <div class="card-collapse mb-0">
                                                <div class="pb-3 pr-2 pt-3 card-header" role="tab" [id]="'headingMe'+i">
                                                    <div class="position-absolute person-img">
                                                        <div class="person-me-icon icon-wrapper text-center">
                                                            <i class="material-icons person-me-icon opacity-10">account_circle</i>
                                                        </div>
                                                    </div>

                                                    <a data-toggle="collapse" [href]="'#collapseMe'+customerIndex" aria-expanded="true" [aria-controls]="'#collapseMe'+customerIndex" class="d-flex justify-content-end flex-column pr-2"> 
                                                        <div class="d-flex justify-content-end">
                                                            <div class="px-0">
                                                                <form [formGroup]="form" appFormValidator>
                                                                    <div class="d-flex editable-name">
                                                                        <mat-form-field class="customer-name">
                                                                            <input matInput type="text" class="input-field user-title" id="editableUser" minlength="3" maxlength="12" required (focus)="$event.target.select();enableNameEdit = true" (focusin)="selfUpdateUser($event);pauseRefreshing=true" (focusout)="selfUpdateUser($event);pauseRefreshing=false; enableNameEdit = false" #customerName [class.disabled-input]="!enableNameEdit" [value]="getCustomerName(customerIndex)" (keyup.enter)="$event.target.blur()" formControlName="name">
                                                                                <mat-error>
                                                                                    <app-form-field-error [control]="form.controls['name']" class="focusable-input"></app-form-field-error>
                                                                                </mat-error>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div class="text-right px-0 edit-img-box">
                                                                <span *ngIf="!enableNameEdit" class="material-icons edit-user-profile-icon" id="editIcon" (click)="startEditUser($event)">edit</span>
                                                                <span *ngIf="enableNameEdit" class="material-icons edit-user-profile-icon" id="confirmIcon" (click)="selfUpdateUser($event)">check</span>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex justify-content-end">
                                                            <p class="font-weight-bold mb-0">
                                                                {{getTotal(customer?.order_products).toFixed(2)}}  {{_settingsService.currency$ | async}}
                                                            </p>
                                                        </div>
                                                        <span class="pr-1"><i class="material-icons float-right">keyboard_arrow_down</i></span>
                                                    </a>
                                                </div>
                                                <hr class="dark horizontal my-0">
                                                <div [id]="'collapseMe'+customerIndex" role="tabpanel" aria-labelledby="headingOne" [attr.data-parent]="'#accordion-manual-customer-me'+customerIndex" class="show">
                                                    <div class="card-body px-0"> 
                                                        <li *ngFor="let product of customer?.order_products; index as i" class="pl-2 mt-3 product-list-item" (click)="openQR(product)">                                                          
                                                            <div class="px-3 pb-2"
                                                                [class.border-paid]="product.payment_id" 
                                                                [class.border-confirmed]="product.confirmed_at && !product.payment_id" 
                                                                [class.border-default]="!product.confirmed_at">
                                                                <div class="col-12 font-weight-bold text-wrap line-height-normal px-0" 
                                                                    [class]="!product.confirmed_at ? 'col-10' : 'col-8'"><p class="mb-2">{{product.product.name}}</p>
                                                                </div>
                                                                <div class="d-flex mb-1">
                                                                    <div class="col-8 px-0">
                                                                        <small class="item-secondary-details text-muted mb-0">{{ math.trunc(product.product.amount) }} {{ product.product.measurement_unit.name }} / {{ product.product.servings }}
                                                                            <span class="" *ngIf="(product.product.servings == 1); else multipleServings">{{ 'Serving' | translate }}</span>
                                                                            <ng-template #multipleServings>{{ 'Servings' | translate }}</ng-template>
                                                                        </small>
                                                                    </div>
                                                                    <div class="col-4 text-right px-0">
                                                                        <div class="" *ngIf="!product.confirmed_at && !product.unavailable_at; else confirmedProduct" (click)="removeProduct(product)">
                                                                            <i class="material-icons-outlined text-danger icon-delete">delete</i>
                                                                        </div>
                                                                        <ng-template #confirmedProduct>
                                                                            <div class="" 
                                                                                [class.badge-paid]="product.payment_id" 
                                                                                [class.badge-confirmed]="product.confirmed_at && !product.payment_id && ! product.served_at"
                                                                                [class.badge-ready-to-pick]="product.ready_at && !product.served_at"
                                                                                [class.badge-product-unavailable]="product.unavailable_at"
                                                                                [class.badge-product-served]="product.served_at"
                                                                                >
                                                                                <span class="font-weight-bold" *ngIf="product.payment_id">{{ "Paid" | translate }}</span>
                                                                                <span class="font-weight-bold" *ngIf="product.confirmed_at && !product.payment_id && !product.ready_at && !product.unavailable_at">{{ "Confirmed" | translate }}</span>
                                                                                <span class="font-weight-bold" *ngIf="product.ready_at && !product.served_at">{{ "Prepared" | translate }}</span>
                                                                                <span class="font-weight-bold" *ngIf="product.unavailable_at">{{ "unavailable" | translate }}</span>
                                                                                <span class="font-weight-bold" *ngIf="product.served_at">{{ "Served" | translate }}</span>
                                                                            </div>
                                                                        </ng-template>
                                                                    </div>
                                                                </div>
                                                                <div class="row align-items-start px-3">
                                                                    <div class="col-2 px-0 d-flex align-items-baseline quantity" [class.disabled-input]="product.confirmed_at || product.unavailable_at">
                                                                            <mat-form-field class="qty-input">
                                                                                <input matInput type="number" [id]="'quantity'+ product.product.id" pattern="^0[1-9]|[1-9]\d$" step="1" class="text-center pb-2" [value]="product.quantity" (focusin)="updateQuantity(product, $event)" (focus)="$event.target.select()" (focusout)="updateQuantity(product, $event)" (keyup.enter)="$event.target.blur()">
                                                                            </mat-form-field>
                                                                            <span class="pl-2">x</span>
                                                                    </div>
                                                                    <div class="col-4 pr-0" [class.disabled-input]="product.confirmed_at || product.unavailable_at">{{product.product.price}} {{_settingsService.currency$ | async}}
                                                                    </div>
                                                                    <div class="col-6 text-right pr-0" [class.disabled-input]="product.confirmed_at || product.unavailable_at">{{(parseFloat(product.quantity) * parseFloat(product.product.price)).toFixed(2)}}  {{_settingsService.currency$ | async}}
                                                                    </div>
                                                                    <div class="customer-obs col-12 px-0 observations mt-3" 
                                                                    [class.d-none]="(product.confirmed_at || product.unavailable_at) && !product.observations" 
                                                                    [class.disabled-input]="product.confirmed_at">
                                                                        <mat-form-field class="customized">
                                                                            <mat-label [for]="'observations'+ product.product.id" class="observations-label small">{{ 'Observations' | translate }}:</mat-label>
                                                                            <textarea type="textarea" matInput class="pb-3 focusable-input"
                                                                            [id]="'observations'+ product.product.id" 
                                                                            rows="1" class="" 
                                                                            value="{{product.observations}}" 
                                                                            (focusin)="updateObservations(product, $event); pauseRefreshing=true" 
                                                                            (focusout)="updateObservations(product, $event);pauseRefreshing=false" 
                                                                            (keyup.enter)="$event.target.blur()">
                                                                            </textarea>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr class="horizontal dark mt-0 mb-0">
                                                        </li>
                                                        <div class="p-3">
                                                            <div class="row align-items-center">
                                                                <div class="col-12 text-right font-custom-regular">{{"CustomerTotal" | translate}}:</div>
                                                                <div class="col-12 text-right">
                                                                    <p class="font-weight-bold mb-0">{{getTotal(customer?.order_products).toFixed(2)}}  {{_settingsService.currency$ | async}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="row align-items-center">
                                                            <div class="col-12 text-center font-custom-regular" *ngIf="requestAlreadyExist">
                                                                <div *ngIf="getOrderStatusMessage(customer?.order_products) === 1" class="p-3">
                                                                    <div class="customer-box-shadow p-3">
                                                                        <i class="material-icons payment-icon opacity-10">notifications</i>
                                                                        <p class="mb-0">{{ "WaiterWillComeToConfirm" | translate }}</p>
                                                                    </div>

                                                                </div>
                                                                <div *ngIf="getOrderStatusMessage(customer?.order_products) === 2" class="p-3">
                                                                    <div class="customer-box-shadow p-3">
                                                                        <i class="material-icons payment-icon opacity-10">notifications</i>
                                                                        <p class="mb-0">{{ "WaiterWillComeToCash" | translate }}</p>
                                                                    </div>
                                                                </div>                                                                  
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <ng-template #noProductsAddedYet>
                <div class="container py-5" *ngIf="!isLoading; else loadingProducts">
                    <h5 class="font-custom-500 mb-0">{{"YouHaveNoProductsAddedYet" | translate}}</h5>
                </div>
                <ng-template #loadingProducts>
                    <div class="container py-5" >
                        <h5 class="font-custom-500 mb-0">{{"LoadingProducts" | translate}}</h5>
                    </div>
                </ng-template>
            </ng-template>

            <!-- <app-call-waiter-form [requestAlreadyExist]="requestAlreadyExist" (onCallWaiter)="callWaiter($event)"></app-call-waiter-form> -->

            <ul class="customer-card px-0 py-3 my-0" *ngFor="let customer of products?.customers; index as i" [class.d-none]="getMyOrder(customer)">
                <div class="px-0 py-0">
                    <div class="col-12 mt-lg-0 mb-md-0">
                        <div class="card my-0 position-relative">
                            <div [id]="'accordion-manual-customer'+i" role="tablist">
                                <div class="card-collapse mb-0">
                                    <div class="pb-3 pr-2 pt-3 card-header" role="tab" [id]="'heading'+i">
                                        <div class="position-absolute person-img">
                                            <div class="person-icon icon-wrapper text-center">
                                                <i class="material-icons person-icon opacity-10">account_circle</i>
                                            </div>
                                        </div>
                                        <a data-toggle="collapse" [href]="'#collapseOne'+i" aria-expanded="false" [aria-controls]="'#collapseOne'+i" class="collapsed d-flex justify-content-end flex-column pr-2"> 
                                            <div class="d-flex justify-content-end">
                                                <div class="px-0">
                                                    <small class="user-title mb-0">{{customer.name? customer.name : 'Customer ' + ( i + 1 )}}</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-end">
                                                <p class="font-weight-bold mb-0">
                                                    {{getTotal(customer?.order_products).toFixed(2)}}  {{_settingsService.currency$ | async}}
                                                </p>
                                            </div>
                                            <span class="pr-1"><i class="material-icons float-right">keyboard_arrow_down</i></span>
                                        </a>
                                    </div>
                                    <hr class="dark horizontal my-0">
                                    <div [id]="'collapseOne'+i" role="tabpanel" aria-labelledby="headingOne" [attr.data-parent]="'#accordion-manual-customer'+i" class="collapse">
                                        <div class="card-body px-0"> 
                                            <li *ngFor="let product of customer?.order_products; index as j" class="pl-2 mt-3 product-list-item">
                                                
                                                <!-- <div class="px-3 pb-2" 
                                                    [class.border-paid]="product.payment_id" 
                                                    [class.border-confirmed]="product.confirmed_at && !product.payment_id" 
                                                    [class.border-default]="!product.confirmed_at">
                                                    <div class="row align-items-start px-3">
                                                        <div class="col-8 px-0 font-weight-bold text-wrap line-height-normal">
                                                            <p>{{product.product.name}}</p>
                                                        </div>
                                                        <div class="col-4 ml-auto" *ngIf="!product.confirmed_at; else confirmedProduct"
                                                            [class.badge-default]="!product.confirmed_at">
                                                                <span class="font-weight-bold">{{ "Pending" | translate }}</span>                                     
                                                        </div>
                                                        <ng-template #confirmedProduct>
                                                            <div class="col-4 ml-auto" 
                                                                [class.badge-paid]="product.payment_id" 
                                                                [class.badge-confirmed]="product.confirmed_at && !product.payment_id">
                                                                <span class="font-weight-bold" *ngIf="product.payment_id">{{ "Paid" | translate }}</span>
                                                                <span class="font-weight-bold" *ngIf="product.confirmed_at && !product.payment_id">{{ "Confirmed" | translate }}</span>
                                                            </div>
                                                        </ng-template>
                                                        <div class="col-3 px-0 pt-1">{{product.quantity}} x</div>
                                                        <div class="col-4 px-0 pt-1">{{product.product.price}} {{_settingsService.currency$ | async}}</div>
                                                        <div class="col-5 px-0 pt-1 text-right">{{(parseFloat(product.quantity) * parseFloat(product.product.price)).toFixed(2)}}  {{_settingsService.currency$ | async}}</div>
                                                        <div class="col-12 px-0 pt-1 others-obs small" *ngIf="product.observations">{{product.observations}}</div>
                                                    </div>
                                                </div> -->
                                                
                                                <div class="px-3 pb-2" 
                                                    [class.border-paid]="product.payment_id" 
                                                    [class.border-confirmed]="product.confirmed_at && !product.payment_id" 
                                                    [class.border-default]="!product.confirmed_at">
                                                    <div class="col-12 font-weight-bold text-wrap line-height-normal px-0">
                                                        <p class="mb-2">{{product.product.name}}</p>
                                                    </div>
                                                    <div class="d-flex mb-1">
                                                        <div class="col-8 px-0">
                                                            <small class="item-secondary-details text-muted mb-0">
                                                                {{ math.trunc(product.product.amount) }} {{ product.product?.measurement_unit.name }} / {{ product.product.servings }}
                                                                <span class="" *ngIf="(product.product.servings == 1); else multipleServings">{{ 'Serving' | translate }}</span>
                                                                <ng-template #multipleServings>{{ 'Servings' | translate }}</ng-template>
                                                            </small>
                                                        </div>
                                                        <div class="col-4 text-right px-0">
                                                            <div class="" *ngIf="!product.confirmed_at && !product.unavailable_at; else confirmedProduct"
                                                            [class.badge-default]="!product.confirmed_at">
                                                                <span class="font-weight-bold">{{ "Unconfirmed" | translate }}</span>                                     
                                                            </div>
                                                            <ng-template #confirmedProduct>
                                                                <div class="" 
                                                                    [class.badge-paid]="product.payment_id" 
                                                                    [class.badge-confirmed]="product.confirmed_at && !product.payment_id && !product.served_at"
                                                                    [class.badge-ready-to-pick]="product.ready_at && !product.served_at"
                                                                    [class.badge-product-unavailable]="product.unavailable_at"
                                                                    [class.badge-product-served]="product.served_at"
                                                                    >
                                                                    <span class="font-weight-bold" *ngIf="product.payment_id">{{ "Paid" | translate }}</span>
                                                                    <span class="font-weight-bold" *ngIf="product.confirmed_at && !product.payment_id && !product.ready_at && !product.unavailable_at">{{ "Confirmed" | translate }}</span>
                                                                    <span class="font-weight-bold" *ngIf="product.ready_at && !product.served_at">{{ "Prepared" | translate }}</span>
                                                                    <span class="font-weight-bold" *ngIf="product.unavailable_at">{{ "unavailable" | translate }}</span>
                                                                    <span class="font-weight-bold" *ngIf="product.served_at">{{ "Served" | translate }}</span>
                                                                </div>
                                                            </ng-template>
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="row align-items-start px-3">
                                                        <div class="col-2 pt-1 px-0">{{product.quantity}} x</div>
                                                        <div class="col-4 pt-1 px-0">{{product.product.price}} {{_settingsService.currency$ | async}}</div>
                                                        <div class="col-6 pt-1 px-0 text-right">{{(parseFloat(product.quantity) * parseFloat(product.product.price)).toFixed(2)}}  {{_settingsService.currency$ | async}}</div>
                                                        <div class="col-12 pt-1 others-obs small px-0" *ngIf="product.observations">{{product.observations}}</div>
                                                    </div>
                                                </div>
                                                <hr class="horizontal dark mt-0 mb-0">
                                            </li>
                                            <div class="py-3 pr-2">
                                                <div class="row align-items-center pr-2">
                                                    <div class="col-12 text-right">{{"CustomerTotal" | translate}}:</div>
                                                    <div class="col-12 text-right">
                                                        <p class="font-weight-bold mb-0">{{getTotal(customer?.order_products).toFixed(2)}}  {{_settingsService.currency$ | async}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>

            <div class="px-0 pt-3 my-0">
                <div class="col-12">
                    <div class="card grand-total my-3">
                    <div id="accordion-call-waiter" role="tablist">
                        <div class="card-collapse mb-0">
                            <div role="tab" id="headingOne" class="pt-4 card-header">
                                <div class="position-absolute person-img">
                                    <div class="icon-wrapper amount-icon text-center" *ngIf="products?.totals?.to_pay">
                                        <i class="material-icons amount-icon opacity-10">payment</i>
                                    </div>
                                    <div class="icon-wrapper amount-icon-inactive text-center" *ngIf="!products?.totals?.to_pay">
                                        <i class="material-icons amount-icon opacity-10">payment</i>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end flex-column">
                                    <div class="text-right pr-2">
                                        <div class="d-flex justify-content-end pr-2 pb-3">
                                            <small class="user-title mb-0">{{"OrderTotal" | translate}}</small>
                                        </div>
                                    </div>
                                </div>
                                <hr class="dark horizontal mt-2 mb-0">
                            </div>
                            <div class="card-body px-2">
                                <ul class="px-2" *ngIf="products?.customers?.length">
                                    <li class="product-list-item my-3">
                                        <div class="d-flex justify-content-between">
                                            <p class="mb-0 fs-08">{{"OrderTotal" | translate}}:</p>
                                            <p class="text-right mb-0 fs-08">{{products?.totals?.total || 0}} {{_settingsService.currency$ | async}}</p>
                                        </div>
                                    </li>
                                    <li class="product-list-item my-3">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-weight-bold mb-0 fs-08">{{"RemainingPayment" | translate}}:</p>
                                            <p class="font-weight-bold text-right mb-0 fs-08">{{products?.totals?.to_pay || 0}} {{_settingsService.currency$ | async}}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <ng-template #noOrder>
            <div class="d-flex justify-content-center align-items-center py-4">
                <h5 class="font-weight-bold mb-0">
                    {{"NoOrderAddedYet" | translate}}
                </h5>
            </div>
        </ng-template>
    </mat-dialog-content>
</div>