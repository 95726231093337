import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription, distinctUntilChanged, filter, switchMap, timer } from 'rxjs';
import { AssetsService } from 'src/app/modules/assets/services/assets.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { OpenTableAssetModalComponent } from './open-table-asset-modal/open-table-asset-modal.component';
import { DimensionsEnum } from 'src/app/shared/enums/Dimensions.enum';
import { OrdersService } from 'src/app/modules/orders/services/oerders.service';
import { CustomerRequestsService } from 'src/app/modules/customer-requests/services/customer-requests.service';
import { SweetAlertService } from 'src/app/shared/services/sweet-alert.service';
import { parseMultiselectFilters } from 'src/app/shared/utils/form.utils';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { TranslateModule } from '@ngx-translate/core';
import { StrippedTableDirective } from '../../../../shared/directives/stripped-table.directive';
import { DropdownWithSearchComponent } from '../../../../shared/components/dropdown-with-search/dropdown-with-search.component';
import { FormFieldErrorComponent } from '../../../../shared/components/form-field-error/form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormValidatorDirective } from '../../../../shared/directives/form-validator.directive';
import { NgIf, NgFor, TitleCasePipe, UpperCasePipe } from '@angular/common';
@UntilDestroy()
@Component({
    selector: 'app-table-assets',
    templateUrl: './table-assets.component.html',
    styleUrls: ['./table-assets.component.css'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, FormValidatorDirective, MatFormFieldModule, MatInputModule, FormFieldErrorComponent, DropdownWithSearchComponent, NgFor, StrippedTableDirective, TitleCasePipe, TranslateModule, UpperCasePipe]
})
export class TableAssetsComponent implements OnDestroy {

    public allAssets;
    public activeCustomerRequests;
    public activeOrderAssets;
    public noOrderAssets;
    public assetTypes;
    public form: FormGroup;
    public subscription: Subscription;
    public refreshTimer = this._settingsService.waiterPanelRefresh$.value || 30000;

    @ViewChild('dropdownActive') dropdownActive: ElementRef;

    constructor(private _assetsService: AssetsService,
        private _dialogService: DialogService,
        private _ordersService: OrdersService,
        private _customerRequestsService: CustomerRequestsService,
        private _sweetAlertService: SweetAlertService,
        private _formBuilder: FormBuilder,
        private _settingsService: SettingsService
    ) {

        this.subscription = timer(0, this.refreshTimer).pipe(
            switchMap(() => this._assetsService.getAssetsNotPaginated$())
        ).subscribe(res => !this.dropdownActive?.nativeElement.classList.contains('show') && this._assetsService.itemsListNotPaginated$.next(res.data));

        this._assetsService.getAssetTypes();
        this._assetsService.itemsListNotPaginated$.pipe(filter(data => !!data), untilDestroyed(this), distinctUntilChanged()).subscribe(data => {
            if ((JSON.stringify(this.allAssets) !== JSON.stringify(data))) {
                if (this.allAssets !== undefined) {
                    this.assetsChangedNotif(data, this.allAssets)
                }
                this.allAssets = data;
                this.activeCustomerRequests = data.filter(asset => asset.customer_request).length;
                this.activeOrderAssets = data.filter(asset => asset.order).length;
                this.noOrderAssets = data.filter(asset => !asset.order).length;
            }
        });
        this._assetsService.assetTypesList$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(data => this.assetTypes = data);
        this.form = this._formBuilder.group({
            name: '',
            asset_type_id: 0,
            has_not_acknowledged_customer_request: null,
            has_open_order: null
        });

        this.form.controls.asset_type_id.valueChanges.subscribe(data => {
            this.renewSubscription(parseMultiselectFilters({ ...this.form.value, asset_type_id: data }));

        })

        this._customerRequestsService.customerRequestUpdated$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(() => this.renewSubscription(parseMultiselectFilters(this.form.value)));
        this._ordersService.orderClosed$.pipe(filter(data => !!data), untilDestroyed(this)).subscribe(() => this.renewSubscription(parseMultiselectFilters(this.form.value)));
    }

    ngOnDestroy() { this.subscription?.unsubscribe() }

    seeOrder(asset) {
        const modalRef = this._dialogService.openModal({ ...asset.order, asset_name: asset.asset_type.name + ' ' + asset.name, editable: true }, OpenTableAssetModalComponent, DimensionsEnum.AUTO);
        modalRef.componentInstance.onCloseOrder.subscribe(event => this.closeOrder(event, modalRef));
        modalRef.afterClosed().subscribe(() => this.renewSubscription(parseMultiselectFilters(this.form.value)));
        this.subscription.unsubscribe();
    }

    closeOrder(asset: any, modalRef?: any) {
        this._sweetAlertService.showSwal('closeOrder', () => this._ordersService.updateOrder({ id: asset.order.id, is_open: false }, modalRef), asset)
    }

    deleteOrder(asset) {
        this._sweetAlertService.showSwal('deleteOrder', () => this._ordersService.deleteOrder(asset.order), asset)

    }

    acknowledgeCustomerRequest(asset) {
        this._sweetAlertService.showSwal('acknowledgeOrder', () => this._customerRequestsService.updateCustomerRequest(asset.customer_request.id), asset)
    }

    getCustomerRequestsAssets() {
        this.form.controls.has_not_acknowledged_customer_request.setValue(this.form.controls.has_not_acknowledged_customer_request.value ? null : true)
        this.renewSubscription(parseMultiselectFilters(this.form.value));
    }

    getOpenOrderAssets() {
        let value = this.form.controls.has_open_order.value;
        value === true ? this.form.controls.has_open_order.setValue(null) : this.form.controls.has_open_order.setValue(true);
        this.renewSubscription(parseMultiselectFilters(this.form.value));
    }

    getNoOrderAssets() {
        let value = this.form.controls.has_open_order.value;
        value === false ? this.form.controls.has_open_order.setValue(null) : this.form.controls.has_open_order.setValue(false);
        this.renewSubscription(parseMultiselectFilters(this.form.value));
    }

    resetFilters() {
        this.form.reset();
        this.renewSubscription();
    }

    filterByName(e) {
        this.renewSubscription(parseMultiselectFilters({ ...this.form.value, name: e.target.value }));
    }

    hasActiveFilters() {
        return Object.keys(this.form.value).some(k => this.form.value[k] !== null)
    }

    renewSubscription(filters?: string) {
        this.subscription && this.subscription.unsubscribe();
        this.subscription = timer(0, this.refreshTimer).pipe(
            switchMap(() => this._assetsService.getAssetsNotPaginated$(filters))
        ).subscribe(res => !this.dropdownActive?.nativeElement.classList.contains('show') && this._assetsService.itemsListNotPaginated$.next(res.data))
    }

    createOrder(asset) {
        const modalRef = this._dialogService.openModal({ asset_name: asset.asset_type.name + ' ' + asset.name, asset_id: asset.id, editable: true }, OpenTableAssetModalComponent, DimensionsEnum.AUTO)
        modalRef.componentInstance.onCloseOrder.subscribe(event => this.closeOrder(event, modalRef));
        modalRef.afterClosed().subscribe(() => this.renewSubscription(parseMultiselectFilters(this.form.value)));
    }

    assetsChangedNotif(data, assets) {
        this.pushNotif()
    }

    pushNotif() {
        Notification.requestPermission().then((Permission) => {
            const notificationOptions = {
                body: "Check the app!!",
                icon: "./assets/img/menu-app-logo-w.png",
                // icon: "./assets/img/sip&bite_secondary_white.png",
                duration: 10
            }
            new Notification("New customer request", notificationOptions);
        })
    }

    identifyAsset(index, asset) {
        return index
    }
}
