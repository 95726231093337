<!-- <footer class="footer ">
    <div class="container-fluid">
        <div class="copyright d-flex justify-content-between">
            <span>v {{ currentBuildNumber }}</span>
            <span>&copy; {{ test | date: 'yyyy' }}, CodeRN Venture</span>
        </div>
    </div>
</footer> -->
<footer class="footer">
    <div class="container-fluid px-md-4">
        <div class="d-flex align-items-center justify-content-around">
        <div class="col-7 px-0">
            <div class="text-left">
                <!-- <div><p class="small mb-0">&copy; {{ test | date: 'yyyy' }}, CodeRN Venture</p></div>
                <div><p class="small mb-0">v {{ currentBuildNumber }}</p></div> -->
                <p class="small mb-0">&copy; {{ test | date: 'yyyy' }}, <a class="app-link" href="https://codern.eu/" target="_blank">CodeRN Venture</a></p>
                <p class="small mb-0">v {{ currentBuildNumber }}</p>
            </div>
        </div>
        <div class="col-5 text-right px-0">
            <a class="app-link" href="https://sip-and-bite.eu/" target="_blank">
                <img src="../../assets/img/sip&bite_secondary_transp_no-margins.png" width="140px" height=""  alt="img" class="">
            </a>
        </div>
    </div>
    </div>
</footer>