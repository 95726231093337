<section class="">
    <div class="container px-0">
        <div class="row">
            <div class="col-12">
                <span>{{ 'Dashboard' | translate | uppercase }}</span>
            </div>
            <div class="col-12 col-sm-12 d-flex justify-content-end mb-3">
                <div class="row">
                    <div class="col-4 p-0">
                        <button class="notifications-btn position-relative btn btn-secondary mx-3" 
                        (click)="getCustomerRequestsAssets()" 
                        [class.active-filter-btn]="form.controls.has_not_acknowledged_customer_request.value">
                            <span class="notification waiter-notif customer-req-notif" *ngIf="activeCustomerRequests">{{activeCustomerRequests}}</span>
                            <i class="material-icons text-danger">notifications_active</i>
                        </button>
                    </div>
                    <div class="col-4 p-0">
                        <button class="notifications-btn position-relative btn btn-secondary mx-3" 
                        (click)="getOpenOrderAssets()" 
                        [class.active-filter-btn]="form.controls.has_open_order.value">
                            <span class="notification waiter-notif custom-active-not" *ngIf="activeOrderAssets">{{activeOrderAssets}}</span>
                            <i class="material-icons text-success">restaurant</i>
                        </button>
                    </div>
                    <div class="col-4 p-0">
                        <button class="notifications-btn position-relative btn btn-secondary mx-3" 
                        (click)="getNoOrderAssets()" 
                        [class.active-filter-btn]="form.controls.has_open_order.value === false">
                            <span class="notification waiter-notif custom-inactive" *ngIf="noOrderAssets">{{noOrderAssets}}</span>
                            <i class="material-icons text-muted">no_meals</i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12">
                <form [formGroup]="form" appFormValidator (keydown.enter)="$event.preventDefault()">
                    <div class="form-row">
                        <div class="col-6 col-sm-6">
                            <mat-form-field class="customized">
                                <mat-label for="name">{{ 'AssetName' | translate }}:</mat-label>
                                <input type="text" matInput id="name" autocomplete="off" formControlName="name" (focusout)="filterByName($event)" (keyup.enter)="$event.target.blur()">
                                <mat-error>
                                    <app-form-field-error [control]="form.controls.name"></app-form-field-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-6">
                            <app-dropdown-with-search 
                                [control]="form.controls.asset_type_id" 
                                [label]="'AssetType'"
                                [firstOptionMessage]="'All'"
                                [itemsList]="assetTypes"
                                [cypressSelector]="'assetTypes'">
                            </app-dropdown-with-search>
                        </div>
                        <div class="col d-flex align-items-center justify-content-end">
                            <button class="btn btn-secondary" 
                                [class.has-active-filters]="hasActiveFilters()" 
                                (click)="resetFilters()">{{"ResetFilters" | translate}}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
</section>

<section>
    <div class="container" *ngIf="allAssets?.length">
        <div class="row pt-4 pb-1">
            <div class="w-30 justify-content-center text-center d-flex small">
                #
            </div>
            <div class="col small font-weight-bold">
                {{"Name" | translate}}
            </div>
            <div class="col-1 text-center small font-weight-bold text-custom-height">
                {{ "CustomerRequest" | translate }}
            </div>
            <div class="col-1 text-center small font-weight-bold text-custom-height">
                {{ "RequestType" | translate }}
            </div>
            <div class="col-1 text-center small font-weight-bold text-custom-height">
                {{ "HasOrderOpen" | translate }}
            </div>
            <div class="col-1 text-center small font-weight-bold text-custom-height">
                {{ "HasUnconfirmedProducts" | translate }}
            </div>
            <div class="col-1 text-right small font-weight-bold text-custom-height">
                {{ "Actions" | translate }}
            </div>
        </div>
        <div class="row py-2" *ngFor="let asset of allAssets; index as i; trackBy:identifyAsset" [appStrippedTable]="i">
            <div class="w-30 justify-content-center d-flex align-items-center small">{{i + 1}}</div>
            <div class="col d-flex align-items-center font-weight-bold" (click)="asset.order?.is_open && seeOrder(asset)">
                {{ asset.name }}
            </div>
            <div class="col-1 text-center d-flex justify-content-center align-items-center small">
                <div *ngIf="asset.customer_request" (click)="acknowledgeCustomerRequest(asset)">
                    <i class="material-icons text-danger" >notifications_active</i>
                </div>
            </div>
            <div class="col-1 text-center small d-flex justify-content-center align-items-center">
                <div class="d-flex flex-column" *ngIf="asset.customer_request">
                    <span>{{asset.customer_request.type | titlecase | translate}}</span>
                </div>
            </div>
            <div class="col-1 text-center small d-flex justify-content-center align-items-center">
                <span>
                    <i class="material-icons text-success" *ngIf="asset.order?.is_open; else noOpenOrder">restaurant</i>
                </span>
                <ng-template #noOpenOrder><i class="material-icons inactive-icon">no_meals</i></ng-template>
            </div>
            <div class="col-1 text-center small d-flex justify-content-center align-items-center">
                <span>
                    <i class="material-icons text-warning" *ngIf="asset.order?.has_unconfirmed_products">feedback</i>
                </span>
            </div>
            <div class="col-1 d-flex justify-content-end align-items-center">
                <div class="dropdown">
                    <button href="#"
                            class="btn btn-drop-custom"
                            data-toggle="dropdown"
                            aria-expanded="true">
                        <i class="material-icons">arrow_drop_down</i>
                    </button>
                    <ul #dropdownActive class="dropdown-menu dropdown-menu-right">
                        <ng-container>
                            <li *ngIf="asset.order?.is_open" class="d-flex align-items-center py-1">
                                <i class="material-icons">visibility</i>
                                <a class="w-100" (click)="seeOrder(asset)">{{ "SeeOrder" | translate }}</a>
                            </li>
                            <li *ngIf="!asset.order" class="d-flex align-items-center py-1">
                                <i class="material-icons">border_color</i>
                                <a class="w-100" (click)="createOrder(asset)">{{ "CreateOrder" | translate }}</a>
                            </li>
                            <li *ngIf="asset.customer_request" class="d-flex align-items-center py-1">
                                <i class="material-icons">assignment_turned_in</i>
                                <a class="w-100" (click)="acknowledgeCustomerRequest(asset)">{{ "AcknowledgeCustomerRequest" | translate }}</a>
                            </li>
                            <li *ngIf="asset.order?.can_be_closed" class="d-flex align-items-center py-1">
                                <i class="material-icons">download_done</i>
                                <a class="w-100" (click)="closeOrder(asset)">{{ "CloseOrder" | translate }}</a>
                            </li>
                            <li class="dropdown-divider" *ngIf="asset.order"></li>
                            <li *ngIf="asset.order?.is_open" class="d-flex align-items-center py-1 text-danger">
                                <i class="material-icons">delete</i>
                                <a class="w-100" (click)="deleteOrder(asset)">{{ "DeleteOrder" | translate }}</a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!allAssets?.length">
        <div class="d-flex justify-content-center my-3 w-100"><span data-cy="no-results">{{ "NoResults" | translate }}</span></div>
    </ng-container>
</section>