import { Routes } from "@angular/router";
import { DashboardComponent } from "../dashboard.component";
import { RestrictPermissionsGuard } from "src/app/shared/guards/restrict-permissions.guard";
import { TableAssetsComponent } from "./table-assets/table-assets.component";
import { OrderedProductssComponent } from "../../ordered-products/ordered-products.component";


export const DashboardAssetsRoutes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        children: [
            {
                path: '',
                redirectTo: 'ordered-products',
                pathMatch: 'full'
            },
            {
                path: 'panel',
                component: TableAssetsComponent,
                canActivate: [RestrictPermissionsGuard]
            },
            {
                path: 'ordered-products',
                component: OrderedProductssComponent,
                canActivate: [RestrictPermissionsGuard]
            },
        ]
    }
];
