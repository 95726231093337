<div class="customer-modal">
    <div class="top-header py-1">
        <div class="dialog-top-header d-flex w-100" mat-dialog-title id="mat-mdc-dialog-title-0">
            <div class="col-9">
                <h5 class="asset-name font-custom-500 white">{{'AddProductToOrder' | translate}}</h5>
            </div>
            <div class="col-3 text-right">
                <button class="btn btn-secondary-custom small-btn font-custom-500 white"
                        (click)="dialogRef.close()">
                    <span class="material-icons">close</span>
                </button>
            </div>
        </div>
    </div>
    <mat-dialog-content class="p-0">
        <div class="add-product-modal py-3">
            <div class="customer-card px-0 py-3 my-0">
                <div class="px-0 py-0">
                    <div class="col-12 mt-lg-0 mb-md-0">
                        <div class="card my-0 position-relative">
                            <div class="card-header pb-3 px-2">
                                <!-- <div class="position-absolute person-img">
                                    <div class="person-icon-wrapper text-center">
                                        <i class="material-icons person-icon opacity-10">border_color</i>
                                    </div>
                                </div> -->
                                <div class="text-right pr-2">
                                    <div class="d-flex justify-content-end">
                                        <div class="px-0">
                                            <small class="user-title mb-0">{{data.name}}</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end ">
                                        <p class="font-weight-bold mb-0">
                                            {{data.price}} {{settingsService.currency$ | async}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr class="dark horizontal my-0">
    
                            <form [formGroup]="form" appFormValidator>
                                <div class="mt-3">
                                    <div class="d-flex">
                                        <div class="col-12 add-to-cart">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div><small>{{'Quantity' | translate}}:</small></div>
                                                <div class="d-flex align-items-center pull-right">
                                                    <button class="btn small-btn modify-qty" (click)="modifyQuantity('remove')"> <i class="material-icons">remove</i></button>
                                                    <span class="px-3">{{quantity}}</span>
                                                    <button class="btn small-btn modify-qty" (click)="modifyQuantity('add')"><i class="material-icons">add</i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                
                                    <div class="customer-obs col-12 px-0 observations mt-3">

                                        <mat-form-field class="w-100 mb-0 observations customer-obs px-3" floatLabel="always">
                                            <mat-label for="observations" class="small">{{ 'Observations' | translate }}:</mat-label>
                                            <textarea matInput id="observations" autocomplete="off" rows="1" formControlName="observations" class="observations"></textarea>
                                            <mat-error>
                                                <app-form-field-error 
                                                [control]="form.controls.observations">
                                            </app-form-field-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="py-3 pr-3 d-flex justify-content-end">
                                    <button type="button" class="button order-btn mr-2" (click)="addProductToCart()">{{"AddToOrder" | translate}}</button>
                                    <button type="button" class="button cancel-btn" (click)="dialogRef.close()">{{ 'Cancel' | translate }}</button>
                                </div>
                            </form>
                                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>